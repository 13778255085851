<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'link-button': true,
    'link-button-theme-orange-light': theme === 'orange-light',
    'link-button-theme-orange-main': theme === 'orange-main',
    }">
    <span>{{ $t('view') }}</span>

  </div>
</template>

